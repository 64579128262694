/** @format */

export const APPLICATIONS = {
  APPAREL: {
    id: 'APPAREL',
    name: 'Apparel',
  },
  HOTELS: {
    id: 'HOTELS',
    name: 'Hotels',
  },
};

export const EMBED_INFO: { [key: string]: any } = {
  APPAREL: {
    dashboardEmbedId: 'aovARPWxlV',
    reportBuilderEmbedId: 'Kd6x5ePxoR',
    styles: {
      primaryColor: '#907EFF',
      navbarColor: '#907EFF',
      navbarTextColor: '#FFFFFF',
      sideNavBgColor: '#FAFAFA',
      sideNavTextColor: '#585858',
      sideNavSelectedbgColor: '#EEF3FA',
      dashboardBodyBgColor: '#FAFAFA',
    },
    headerText: 'Apparel',
    sideNavOptions: [
      { name: 'Overview', icon: 'heat-grid' },
      { name: 'Report Builder', icon: 'document' },
      { name: 'Reviews', icon: 'edit' },
      { name: 'Team', icon: 'people' },
      { name: 'Settings', icon: 'cog' },
    ],
    customers: [
      {
        name: 'Vendor 1',
        id: 1281,
        token: '040e250439d6ac93ff91fbfece27fc09926be71e3ac81f403ec90126a2d97f0c',
      },
      {
        name: 'Vendor 2',
        id: 1282,
        token: 'ab5bfe7afd2dba3c7f19c1e0a82d539adc57f7ea8ff32e0c9d8ca743884d5b86',
      },
      {
        name: 'Vendor 3',
        id: 1283,
        token: 'ba1e9a8400d7ca5137e3f798142eb8c56989ae1817ea05f17849ea73624c2244',
      },
    ],
  },
  HOTELS: {
    dashboardEmbedId: 'oE3Aa38xOq',
    reportBuilderEmbedId: '',
    styles: {
      primaryColor: '#FFFFFF',
      navbarColor: '#373737',
      navbarTextColor: '#FFFFFF',
      sideNavBgColor: '#373737',
      sideNavTextColor: '#B3B3B3',
      sideNavSelectedbgColor: '#414141',
      dashboardBodyBgColor: 'rgb(44, 44, 44)',
    },
    headerText: 'Hotel Management',
    sideNavOptions: [
      { name: 'Overview', icon: 'heat-grid' },
      { name: 'Hotel Details', icon: 'office' },
      { name: 'Guest Directory', icon: 'people' },
      { name: 'Maintenance', icon: 'cog' },
    ],

    customers: [
      {
        name: 'Hotel Arts Barcelona',
        id: 2647,
        token: '41f4926bfa092fc74e5979c924ced2c5c14911ab22a3ccd33397ef3fe2bccbbe',
      },
      {
        name: 'Novotel Milano Linate Aeroporto',
        id: 2648,
        token: '6040ed3b498b141a2af87213bf7edc8fd8bb108e210c1cf8cb01f103e90df52e',
      },
      {
        name: 'Hotel Da Vinci',
        id: 2649,
        token: 'a21726e62248499889dc84085871fb7a45277f5263befb58ea95b5875c4082bc',
      },
    ],
  },
};

export const LINK_ROUTES = ['/', '/report'];
