/** @format */

import React, { useState } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, Intent } from '@blueprintjs/core';
import { ReduxState } from 'reducers/rootReducer';

import SetupFlowPage from 'components/pages/setupFlowPage';
import InputField from 'explo-ds/forms/marketing/inputField';
import Button from 'explo-ds/buttons/button';

import { loginUser } from 'actions/authActions';

const useStyles = makeStyles((theme: Theme) => ({
  signupInput: {
    backgroundColor: theme.palette.grey200,
    width: '100%',
    height: 40,
    marginTop: 2,
  },
  passwordInputGroup: {
    marginTop: theme.spacing(5),
  },
  signInButton: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
}));

type Props = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const SignIngPageV2 = (props: Props) => {
  return <SetupFlowPage title="Sign in" pageBody={<SignInBody {...props} />} />;
};

const SignInBody = (props: Props) => {
  const [password, setPassword] = useState('');

  const classes = useStyles();

  const onSubmit = (password: string) => {
    props.loginUser(password);
  };

  return (
    <div>
      <FormGroup className={cx('bp3-dark', classes.passwordInputGroup)} label="Password">
        <InputField
          value={password}
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
          className={classes.signupInput}
          placeholder=""
        />
      </FormGroup>
      <Button
        className={classes.signInButton}
        intent={Intent.PRIMARY}
        onClick={() => {
          onSubmit(password);
        }}
        large>
        Sign In
      </Button>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {
  loginUser,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIngPageV2));
