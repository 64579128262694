/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import AppNavHeader from 'pages/shared/AppNavHeader';
import DashboardPageContainer from 'pages/shared/dashboardPageContainer';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  pageBody: {},
  contentContainer: {
    marginTop: 120,
    margin: `0 ${theme.spacing(8)}px`,
  },
  tabsContainer: {
    boxShadow: 'rgb(235, 238, 241) 0px -1px 0px 0px inset',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    zIndex: 10,
    backgroundColor: 'white',
    marginTop: -16,
  },
  tabOption: {
    color: '#6A7383',
    height: 35,
    fontSize: 13,
    fontWeight: 'bold' as 'bold',
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.spacing(3)}px`,
    borderBottom: '2px solid #FFFFFF',

    '&:first-child': {
      marginLeft: 0,
    },

    '&:hover:not(.selected)': {
      color: '#000000',
      cursor: 'pointer',
    },

    '&.selected': {
      color: '#635BFF',
      borderBottom: '2px solid #635BFF',
    },
  },
  dashboardContainer: {
    margin: '0 5px',
  },
}));

export default function OrdersPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppNavHeader />
      <div className={classes.pageBody}>
        <DashboardPageContainer>
          <div className={classes.dashboardContainer}>
            <explo-dashboard
              dash-user-group-token={
                'GL21X6DYQo:9a40bb523dc354d4e2629d1d884af8d1eb0b71961df8b14a538c048354d59fe6'
              }
              environment="production"
            />
          </div>
        </DashboardPageContainer>
      </div>
    </div>
  );
}
