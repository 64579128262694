/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import parse from 'url-parse';

import AppNavHeader from 'pages/shared/AppNavHeader';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  pageBody: {
    paddingTop: 44,
  },
  dashboardContainer: {
    margin: '0 5px',
  },
}));

export default function ReportPage() {
  const classes = useStyles();

  const { user_group_id } = parse(window.location.href, true).query;

  let embedUrl =
    'https://app.explo.co/end-user-dashboard/iframe/WL9YmpAXlw/9a40bb523dc354d4e2629d1d884af8d1eb0b71961df8b14a538c048354d59fe6';

  if (user_group_id) {
    embedUrl = `${embedUrl}?userId=${user_group_id}`;
  }

  return (
    <div className={classes.root}>
      <AppNavHeader />
      <div className={classes.pageBody}>
        <iframe
          src={embedUrl}
          style={{ width: '100%', border: 'none', height: 'calc(100vh - 44px)' }}
          title="custom report builder"></iframe>
      </div>
    </div>
  );
}
