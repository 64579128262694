/** @format */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';

import { ROUTES } from 'constants/routes';

const PrivateRoute = ({ component: Component, noMatch, isLoggedIn, ...rest }: any) => {
  const routeComponent = (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          if (props.location.pathname === ROUTES.LOGIN) {
            return <Component {...props} />;
          }
          return noMatch ? <Component {...props} /> : <Redirect to={{ pathname: ROUTES.LOGIN }} />;
        } else if (props.location.pathname === ROUTES.LOGIN) {
          return <Redirect to={{ pathname: ROUTES.HOME }} />;
        }
        return noMatch ? <Redirect to={{ pathname: ROUTES.LOGIN }} /> : <Component {...props} />;
      }}
    />
  );
  return routeComponent;
};

const mapStateToProps = (state: ReduxState) => ({
  isLoggedIn: state.authData.loggedIn,
});

export default connect(mapStateToProps)(PrivateRoute);
