/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { Button, Icon } from '@blueprintjs/core';

import WholeFoodsLogo from 'images/whole-foods.png';

export const NAV_HEIGHT = 44;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: NAV_HEIGHT,
    backgroundColor: '#F7F8FA',
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: 30,
    boxShadow: 'rgba(64, 68, 82, 0.08) 0px 2px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px ',
  },
  iconLogo: {
    width: 20,
  },
  companyName: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    fontWeight: 100,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(8),
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(8),
  },
  logoIconLink: {
    lineHeight: 0,
  },
}));

export type Props = {
  settingSelected?: boolean;
};

export default function AppNavHeader({ settingSelected }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <div className={classes.iconContainer}>
          <a className={classes.logoIconLink} href="/">
            <img src={WholeFoodsLogo} alt="icon logo" className={classes.iconLogo} />
          </a>
        </div>
        <div className={classes.companyName}>Whole Foods</div>
      </div>
      <div className={classes.right}>
        <Button minimal icon="chart" onClick={() => (window.location.href = '/reports')}>
          Reports
        </Button>
        <Button minimal icon="help" onClick={() => window.open('https://docs.explo.co', '_blank')}>
          Help
        </Button>
        <Button
          minimal
          icon={settingSelected ? <Icon icon="cog" color="#635BFF" /> : 'cog'}
          onClick={() => (window.location.href = '/billing')}></Button>
        <Button minimal icon="person"></Button>
      </div>
    </div>
  );
}
