/** @format */

import React, { ReactNode } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.black100,
    height: '100vh',
    padding: `0 ${theme.spacing(7)}px`,
    fontFamily: "'Inter', sans-serif",
    perspective: '1px',
    overflowY: 'auto',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${theme.spacing(7)}px`,
    minHeight: '100%',
  },
  card: {
    backgroundColor: theme.palette.grey300,
    borderRadius: 12,
    padding: `${theme.spacing(15)}px ${theme.spacing(10)}px`,
    height: 'fit-content',
    width: 450,
    maxWidth: '100%',
    position: 'relative',
    margin: '100px 0px',
  },
  title: {
    color: theme.palette.white,
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    textAlign: 'center',
  },
  body: {},
  logoutOption: {
    position: 'absolute',
    top: 20,
    right: 24,
    color: theme.palette.white,
  },
  logoutOptionLink: {
    color: `${theme.palette.white} !important`,
    textDecoration: 'underline',
  },
}));

type Props = {
  className?: string;
  title: string;
  pageBody: ReactNode;
} & typeof mapDispatchToProps;

const SetupFlowPage = (props: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, props.className)}>
      <div className={classes.cardContainer}>
        <div className={classes.card}>
          <div className={classes.title}>{props.title}</div>
          <div className={classes.body}>{props.pageBody}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SetupFlowPage);
