/** @format */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/styles';
import { ReduxState } from 'reducers/rootReducer';
import { RouteComponentProps } from 'react-router';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import AppNavHeader from 'pages/shared/AppNavHeader';
import DashboardPageContainer from 'pages/shared/dashboardPageContainer';

import { APPLICATIONS, EMBED_INFO } from 'constants/shared';
import { TABS } from 'pages/shared/dashboardPageContainer';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}

const styles = (theme: Theme) => ({
  root: {},
  pageBody: {},
  dashboardContainer: {
    margin: '0 5px',
  },
});

type Props = { isReportBuilder?: boolean } & ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

type State = {
  selectedApplicationId: string;
  selectedCustomerId: string;
};

class DashboardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedApplicationId: APPLICATIONS.APPAREL.id,
      selectedCustomerId: EMBED_INFO[APPLICATIONS.APPAREL.id].customers[0].id,
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppNavHeader />
        <div className={classes.pageBody}>
          <DashboardPageContainer selectedTab={TABS.HOME}>
            <div className={classes.dashboardContainer}>
              <explo-dashboard
                dash-user-group-token={
                  'BaQ1yyg18o:9a40bb523dc354d4e2629d1d884af8d1eb0b71961df8b14a538c048354d59fe6'
                }
                environment="production"
              />
            </div>
          </DashboardPageContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardPage)),
);
