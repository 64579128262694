/** @format */

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

import AppNavHeader from 'pages/shared/AppNavHeader';
import DashboardPageContainer from 'pages/shared/dashboardPageContainer';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'explo-dashboard': any;
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  pageBody: {},
  dashboardContainer: {
    margin: '0 5px',
  },
}));

export default function OrdersPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppNavHeader settingSelected />
      <div className={classes.pageBody}>
        <DashboardPageContainer>
          <div className={classes.dashboardContainer}>
            <explo-dashboard
              dash-user-group-token={
                '2bo1qBzAzw:9a40bb523dc354d4e2629d1d884af8d1eb0b71961df8b14a538c048354d59fe6'
              }
              environment="production"
            />
          </div>
        </DashboardPageContainer>
      </div>
    </div>
  );
}
