/** @format */

export enum ROUTES {
  HOME = '/',
  ORDERS = '/orders',
  LOGIN = '/login',
  EXPORT = '/export',
  BILLING = '/billing',
  REPORTS = '/reports',
}
