/** @format */

import React from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/index';
import { useHistory } from 'react-router-dom';

import { NAV_HEIGHT } from 'pages/shared/AppNavHeader';
import { ROUTES } from 'constants/routes';

export const NAV_TABS_HEIGHT = 50;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: `calc(100% - ${NAV_HEIGHT}px)`,
    backgroundColor: theme.palette.white,
  },
  tabsContainer: {
    margin: `0 ${theme.spacing(8)}px`,
    boxShadow: 'rgb(235, 238, 241) 0px -1px 0px 0px inset',
    height: NAV_TABS_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    top: NAV_HEIGHT,
    width: 'calc(100% - 64px)',
    zIndex: 10,
    backgroundColor: 'white',
  },
  tabOption: {
    color: '#1A1B25',
    height: 30,
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 4,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${theme.spacing(3)}px`,

    '&:hover:not(.selected)': {
      backgroundColor: '#EDEEF1',
      cursor: 'pointer',
    },

    '&.selected': {
      backgroundColor: '#635BFF',
      color: 'white',
    },
  },
  contentContainer: {
    marginTop: NAV_HEIGHT + NAV_TABS_HEIGHT,
  },
}));

export type Props = {
  children: JSX.Element;
  selectedTab?: TABS;
};

export enum TABS {
  HOME = 'Home',
  ORDERS = 'Orders',
}

export const TABS_TO_URL = {
  [TABS.HOME]: ROUTES.HOME,
  [TABS.ORDERS]: ROUTES.ORDERS,
};

export default function DashboardPageContainer({ children, selectedTab }: Props) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.tabsContainer}>
        {Object.values(TABS).map((tab) => (
          <div
            className={cx(classes.tabOption, { selected: selectedTab === tab })}
            onClick={() => history.push(TABS_TO_URL[tab])}>
            {tab}
          </div>
        ))}
      </div>
      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
}
