/** @format */

import { ACTION } from 'actions/types';

export const loginUser = (password: string) => ({
  type: ACTION.LOGIN_USER,
  payload: {
    password,
  },
});
